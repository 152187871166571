// ##############################
// // // Header styles
// #############################

import {
  container,
  defaultFont,
  transition,
  boxShadow,
  drawerWidth,
  largeLandscape, // Apricot
} from "assets/jss/material-kit-pro-react.jsx";

import {
  // Apricot
  showFor1024Up,
  showFor1023Down,
  showFor1600Up,
  showFor1599Down,
} from "assets/jss/views/apricot.jsx";

export const logoStyle = {
  display: "inline-block",
  width: "46px",
  height: "24px",
  marginRight: "0.5rem",
};

const headerStyle = (theme) => {
  const primaryColor = theme.palette.primary.main;
  const infoColor = theme.palette.info.main;
  const successColor = theme.palette.success.main;
  const warningColor = theme.palette.warning.main;
  const dangerColor = theme.palette.warning.main;

  const defaultFont = {
    ...defaultFont,
    fontFamily: theme.typography.fontFamily,
  };

  const appBarCommon = {
    // TBD: are all these required?
    display: "flex",
    border: "0",
    color: "#555",

    backgroundColor: "#fff",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    flexFlow: "row nowrap",
    alignItems: "center",
    position: "relative",
  };

  const appBar = {
    ...appBarCommon,
    width: "100%",
    padding: "0.625rem 0",
    justifyContent: "flex-start",
  };

  return {
    // We have three appBar styles:
    // 1. Hide: don't show it
    // 2. normal
    // 3. for Classroom
    appBarHide: { display: "none" },

    appBar,

    appBarClassroom: {
      ...appBar,
      [largeLandscape]: {
        "@media (min-width: 1280px)": {
          width: "30%",
        },
        ...appBarCommon,
        width: "27%",
        height: "50px",
        padding: 0,
        // left: 0, // remove "left: 0" if we put the header at right
        justifyContent: "flex-end",
      },
    },

    absolute: {
      position: "absolute",
      top: "auto",
    },
    fixed: {
      position: "fixed",
    },
    container: {
      ...container,
      minHeight: "50px",
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      "&,& a": {
        "@media (min-width: 350px)": {
          // Apricot:
          fontSize: "1.125rem", // we need at least 350px width to show the brand in normal font size
          maxWidth: "30rem",
        },
        ...defaultFont,
        lineHeight: "30px",
        fontSize: "1rem", // before 350px (e.g. iPhone 5), we use smaller font for brand name
        maxWidth: "10rem", // and limit the size used by brand name. This secures the room for cart icon.
        borderRadius: "3px",
        textTransform: "none",
        whiteSpace: "nowrap",
        color: "inherit",
        "&:hover,&:focus": {
          color: "inherit",
          background: "transparent",
        },
      },
    },
    appResponsive: {
      margin: "20px 10px",
      marginTop: "0px",
    },
    primary: {
      backgroundColor: primaryColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)",
    },
    info: {
      backgroundColor: infoColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)",
    },
    success: {
      backgroundColor: successColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)",
    },
    warning: {
      backgroundColor: warningColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)",
    },
    danger: {
      backgroundColor: dangerColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)",
    },

    transparent: {
      backgroundColor: "rgba(255, 255, 255, 0.2) !important", // Apricot
      boxShadow: "none",
      padding: "0",
      color: "#FFFFFF",
    },
    dark: {
      color: "#FFFFFF",
      backgroundColor: "#212121 !important",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)",
    },
    white: {
      border: "0",
      padding: "0",
      color: "#555",
      backgroundColor: "#fff !important",
      boxShadow:
        "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    },
    drawerPaper: {
      border: "none",
      bottom: "0",
      transitionProperty: "top, bottom, width",
      transitionDuration: ".2s, .2s, .35s",
      transitionTimingFunction: "linear, linear, ease",
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      ...transition,
    },
    hidden: {
      width: "100%",
    },
    collapse: {
      [theme.breakpoints.up("md")]: {
        display: "flex !important",
        MsFlexPreferredSize: "auto",
        flexBasis: "auto",
      },
      WebkitBoxFlex: "1",
      MsFlexPositive: "1",
      flexGrow: "1",
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
    },
    closeButtonDrawer: {
      position: "absolute",
      right: "8px",
      top: "9px",
      zIndex: "1",
    },
    // Apricot
    logo: {
      ...logoStyle,
    },
    showFor1024Up,
    showFor1023Down,
    showFor1600Up,
    showFor1599Down,

    showForLandscape: {
      "@media (orientation: landscape)": {
        display: "inline-block",
      },
      display: "none",
    },
    hideForLandscape: {
      "@media (orientation: landscape)": {
        display: "none",
      },
      display: "inline-block",
    },
  };
};

export default headerStyle;
